import React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import {Table,Paper, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography, useTheme} from '@mui/material';
import './HistoryDialog.css';
const HistoryDialog = ({ isMobile,open, handleClose, data }) => {
    const theme = useTheme();
    return (
        <Dialog
            open={open}
            onClose={handleClose}
            maxWidth="md" // Set maxWidth to 'md', 'lg', 'xl', or 'false'
            PaperProps={{
                style: {
                    width: '90%',
                    marginLeft: isMobile ? 10 : 'auto',
                    marginRight: isMobile ? 10 : 'auto'
                }
            }} // Set a custom width
        >
            <DialogTitle>
                <Typography variant="h6" component="div" align="center">History</Typography>
                <IconButton
                    edge="end"
                    color="inherit"
                    onClick={handleClose}
                    aria-label="close"
                    style={{ position: 'absolute', right: theme.spacing(2), top: theme.spacing(1) }}
                >
                    <CloseIcon />
                </IconButton>
            </DialogTitle>
            <DialogContent sx={{ p: isMobile ? 0.5 : 2 }}>
                <TableContainer component={Paper} style={{ overflow: 'auto', maxHeight: 440 }}>
                    <Table sx={{ minWidth: isMobile ? 200 : 350 }} aria-label="simple table">
                        <TableHead>
                            <TableRow sx={{ position: 'sticky', top: 0, zIndex: 1, backgroundColor: '#121212' }}>
                                <TableCell>Date</TableCell>
                                <TableCell>Time</TableCell>
                                <TableCell align="center">Change</TableCell>
                                <TableCell align="center">Changed By</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {[...data].reverse().map((row) => (
                                <TableRow key={row._id}>
                                    <TableCell component="th" scope="row">
                                        {new Date(row.timestamp).toLocaleDateString('en-GB', { day: '2-digit', month: '2-digit', year: 'numeric' })}
                                    </TableCell>
                                    <TableCell>
                                        {new Date(row.timestamp).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })}
                                    </TableCell>
                                    <TableCell sx={{ color: row.changeType === 'increased' ? 'success.main' : 'error.main' }} align="center">{row.change}</TableCell>
                                    <TableCell align="center">{row.changedBy}</TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </DialogContent>
        </Dialog>
    );
};

export default HistoryDialog;