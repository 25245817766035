import React from 'react';
import { Route, Navigate, Routes } from 'react-router-dom';
import HomeComponent from '../Pages/Home/Home';
import AddItem from "../Pages/AddItem/AddItem";
import RegisterUser from "../Pages/RegisterUser/RegisterUser";
const routeConfig = [
    { path: '/home', component: HomeComponent },
    { path: '/', component: HomeComponent },
    { path: '/add-item', component: AddItem },
    { path: '/register', component: RegisterUser },
    // Add more routes here...
];

const ProtectedRoute = ({ children, ...rest }) => {
    const isLoggedIn  = JSON.parse(localStorage.getItem("isLoggedIn"));
    console.log(isLoggedIn)

    return (
        <Routes>
            {routeConfig.map(({ path, component }) => (
                <Route key={path} path={path} element={isLoggedIn ? React.createElement(component) : <Navigate to="/login" replace />} />
            ))}
        </Routes>
    );
};

export default ProtectedRoute;