import axios from "axios";
import {adminApi} from "../Provider/api";
// Access the API_URL environment variable
const API_URL = process.env.REACT_APP_API_URL;
console.log({API_URL})
const login = (phone,password) => {
    return axios.post(API_URL + 'admin/v1/login',{phone,password} )
        .then((response) => {
            if(response.data.token){
                localStorage.setItem("id", (response.data.id));
                localStorage.setItem("token", (response.data.token));
                localStorage.setItem("name", (response.data.name));
                localStorage.setItem("phone", (response.data.phone));
                localStorage.setItem("username", (response.data.username));
                localStorage.setItem("isLoggedIn", true);
            }
            return response.data;
        });
}
const register = (userData) => {
    return adminApi.post( 'register',userData )
        .then((response) => {
            return response.data;
        });
}

const logout = () => {
    localStorage.clear();
};

const getCurrentUser = () => {
    return JSON.parse(localStorage.getItem("user"));
};

const authService = {
    login,
    logout,
    getCurrentUser,
    register
};

export default authService;