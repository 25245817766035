// CustomerService.js
import api from "../Provider/api";
import handleError from "../Provider/errorHandler";

const searchItem = async (itemId) => {
    try {
        return await api.get(`item/v1/${itemId}`);
    } catch (error) {
        handleError(error);
    }
}

const updateItemQuantity = async (itemId, quantity) => {
    try {
        return await api.put(`item/v1/${itemId}`, { quantity });
    } catch (error) {
        handleError(error);
    }
}
const addItem = async (itemData) => {
    try {
        return await api.post(`item/v1/`, itemData);
    } catch (error) {
        return handleError(error);
    }
}
const deleteItem = async (itemId) => {
    try {
        return await api.delete(`item/v1/${itemId}`);
    } catch (error) {
        handleError(error);
    }
}

const itemService = {
    searchItem,
    updateItemQuantity,
    addItem,
    deleteItem
};

export default itemService;