import React, { useState } from 'react';
import {
    Button,
    TextField,
    Typography,
    Grid,
    Paper,
    Box, Select, MenuItem, InputLabel, FormControl, useMediaQuery,
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import UserService from '../../Services/AuthService';
import Swal from "sweetalert2";
import {useNavigate} from "react-router-dom";

function RegisterUserForm() {
    const theme = useTheme();
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [username, setUsername] = useState(''); // Add this line
    const [phone, setPhone] = useState(''); // Add this line
    const [role, setRole] = useState('ADMIN'); // Add this line
    const navigate = useNavigate();
    const handleSubmit = async (event) => {
        event.preventDefault();

        const userData = {
            name,
            email,
            password,
            username, // Add this line
            phone, // Add this line
            role // Add this line
        };

        Swal.fire({
            html: `<span style="color: ${theme.palette.mode === 'dark' ? '#fff' : '#000'}">Processing...</span>`,
            background: theme.palette.mode === 'dark' ? '#424242' : '#fff',
            didOpen: () => {
                Swal.showLoading();
            },
            allowOutsideClick: () => !Swal.isLoading()
        });

        try {
            const response = await UserService.register(userData);
            console.log(response);
            Swal.close();
            Swal.fire({
                html: `<span style="color: ${theme.palette.mode === 'dark' ? '#fff' : '#000'}">Success</span>`,
                text: 'User registered successfully.',
                icon: 'success',
                background: theme.palette.mode === 'dark' ? '#424242' : '#fff',
            });

            // Reset all the state variables
            setName('');
            setEmail('');
            setPassword('');
            setUsername('');
            setPhone('');
            setRole('');
            navigate('/home');

        } catch (error) {
            Swal.close();
            console.error(error);
            Swal.fire({
                html: `<span style="color: ${theme.palette.mode === 'dark' ? '#fff' : '#000'}">Error</span>`,
                text: 'An error occurred while registering the user.',
                icon: 'error',
                background: theme.palette.mode === 'dark' ? '#424242' : '#fff',
            });
        }
    };
    const isMobile = useMediaQuery(theme => theme.breakpoints.down('sm'));
    return (
        <Grid container direction="column" justifyContent="center" alignItems="center" sx={{mt:2}} >
            <Paper elevation={10} style={!isMobile ? {}:{ width: '90vw' }} >
                <Box p={2} display="flex" flexDirection="column" alignItems="center" justifyContent="center" sx={!isMobile ? {}:{mb:2}}>
                    <Typography sx={{m: 2}} variant="h4" component="h1" gutterBottom>
                        Register User
                    </Typography>
                    <form noValidate autoComplete="off" onSubmit={handleSubmit}>
                        <Box display="flex" flexDirection="column" elevation={10} sx={isMobile ? {width:"80vw"}:{width:"50vw"}}>
                            <TextField
                                id="username"
                                label="Username"
                                variant="outlined"
                                value={username}
                                onChange={(e) => setUsername(e.target.value)}
                                style={{marginBottom: theme.spacing(2)}}
                            />
                            <TextField
                                id="phone"
                                label="Phone"
                                variant="outlined"
                                value={phone}
                                onChange={(e) => setPhone(e.target.value)}
                                style={{marginBottom: theme.spacing(2)}}
                            />
                            <FormControl variant="outlined" style={{marginBottom: theme.spacing(2)}}>
                                <InputLabel id="role-label">Role</InputLabel>
                                <Select
                                    labelId="role-label"
                                    id="role"
                                    value={role}
                                    onChange={(e) => setRole(e.target.value)}
                                    label="Role"
                                >
                                    <MenuItem value="ADMIN">Admin</MenuItem>
                                    <MenuItem value="USER">User</MenuItem>
                                </Select>
                            </FormControl>
                            <TextField
                                id="name"
                                label="Name"
                                variant="outlined"
                                value={name}
                                onChange={(e) => setName(e.target.value)}
                                style={{marginBottom: theme.spacing(2)}}
                            />
                            <TextField
                                id="email"
                                label="Email"
                                variant="outlined"
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                                style={{marginBottom: theme.spacing(2)}}
                            />
                            <TextField
                                id="password"
                                label="Password"
                                type="password"
                                variant="outlined"
                                value={password}
                                onChange={(e) => setPassword(e.target.value)}
                                style={{marginBottom: theme.spacing(2)}}
                            />
                            <Button
                                variant="contained"
                                color="primary"
                                type="submit"
                                disabled={!name || !email || !password || !username || !phone || !role}
                            >
                                Register User
                            </Button>
                        </Box>
                    </form>
                </Box>
            </Paper>
        </Grid>
    );
}

export default RegisterUserForm;
