import React, {useEffect, useState} from 'react';
import {
    Button,
    TextField,
    Typography,
    useTheme,
    Paper,
    Box,
    Grid,
    Dialog,
    DialogTitle,
    DialogContent,
    TableRow,
    TableContainer,
    Table,
    TableCell,
    TableBody,
    IconButton,
    useMediaQuery,
    Tooltip,
    Alert,
    CircularProgress,
    Backdrop
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import SaveIcon from '@mui/icons-material/Save';
import itemService from '../../Services/itemService';
import DeleteIcon from '@mui/icons-material/Delete';
import HistoryIcon from '@mui/icons-material/History';
import Swal from "sweetalert2";
import HistoryDialog from "../../Components/HistoryDialog/HistoryDialog";
import QuantityInput from "../../Components/QuantityInput/QuantityInput";
import {Snackbar} from "@mui/base";

function HomePage() {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    const [searchResult, setSearchResult] = useState({}); // State variable to hold search result
    const [open, setOpen] = useState(false); // State variable to control Dialog open state
    const [inputQuantity, setInputQuantity] = useState(0); // New state variable for the quantity input
    const [quantityChanges, setQuantityChanges] = useState([]); // New state variable for the quantity changes

    useEffect(() => {
        setInputQuantity(searchResult.quantity); // Update inputQuantity when searchResult.quantity changes
    }, [searchResult.quantity]);

    const handleSearch = async (event) => {
        event.preventDefault();
        const searchTerm = event.target.elements['search-field'].value;

        Swal.fire({
            html: `<span style="color: ${theme.palette.mode === 'dark' ? '#fff' : '#000'}">Searching...</span>`, // set the title with a custom style
            background: theme.palette.mode === 'dark' ? '#424242' : '#fff',
            didOpen: () => {
                Swal.showLoading();
            },
            allowOutsideClick: () => !Swal.isLoading()
        });

        try {
            const response = await itemService.searchItem(searchTerm); // Call the searchItem API
            // console.log(response);
            setSearchResult(response.data); // Update the state variable with the result
            setInputQuantity(response.data.quantity); // Update the inputQuantity state variable
            setQuantityChanges(response.data.quantityChanges); // Update the quantityChanges state variable
            setOpen(true); // Open the Dialog
            Swal.close();

        } catch (error) {
            Swal.close();
            Swal.fire({
                html: `<span style="color: ${theme.palette.mode === 'dark' ? '#fff' : '#000'}">Item Not Found</span>`, // set the title with a custom style
                text: 'An error occurred while searching for the item.',
                icon: 'error',
                background: theme.palette.mode === 'dark' ? '#424242' : '#fff',
            });
        }
    };

    const handleClose = () => {
        setOpen(false); // Close the Dialog
        setInputQuantity(0);
    };
    const [historyDialogOpen, setHistoryDialogOpen] = useState(false);

    const handleHistoryClick = () => {
        setHistoryDialogOpen(true);
    };

    const handleHistoryDialogClose = () => {
        setHistoryDialogOpen(false);
    };
    async function deleteItem(itemId) {
        Swal.fire({
            html: `<span style="color: ${theme.palette.mode === 'dark' ? '#fff' : '#000'}">Deleting...</span>`, // set the title with a custom style
            background: theme.palette.mode === 'dark' ? '#424242' : '#fff',
            didOpen: () => {
                Swal.showLoading();
            },
            allowOutsideClick: () => !Swal.isLoading()
        });
        try {
            await itemService.deleteItem(itemId);
            handleClose();
            Swal.close();
            Swal.fire({
                html: `<span style="color: ${theme.palette.mode === 'dark' ? '#fff' : '#000'}">Success</span>`, // set the title with a custom style
                text: "Item is Deleted",
                icon: 'success',
                background: theme.palette.mode === 'dark' ? '#424242' : '#fff',
            });
        } catch (error) {
            Swal.close();
            Swal.fire({
                html: `<span style="color: ${theme.palette.mode === 'dark' ? '#fff' : '#000'}">Error</span>`, // set the title with a custom style
                text: error.message,
                icon: 'error',
                background: theme.palette.mode === 'dark' ? '#424242' : '#fff',
            });
        }
    }
    const [alertOpen, setAlertOpen] = useState(false);
    const [backdropOpen, setBackdropOpen] = useState(false);
    async function updateQuantity() {
        setBackdropOpen(true)

        try {
            const response=await itemService.updateItemQuantity(searchResult.id, inputQuantity);
            setBackdropOpen(false)
            setQuantityChanges(response.data.quantityChanges); // Update the quantityChanges state variable
            setAlertOpen(true)
        } catch (error) {
            setBackdropOpen(false)
            setAlertOpen(true)

        }
    }

    return (
        <Grid container direction="column" justifyContent="center" alignItems="center" style={{ minHeight: '60vh' }}>
            <Paper elevation={10} style={{ width: '80vw' }}>
                <Box p={2} display="flex" flexDirection="column" alignItems="center" justifyContent="center">
                    <Typography sx={{m:2}} variant="h4" component="h1" gutterBottom>
                        Enter Bearing Number
                    </Typography>
                    <form noValidate autoComplete="off" onSubmit={handleSearch}>
                        <TextField id="search-field" label="Search" variant="outlined" style={{ marginBottom: theme.spacing(2) }} />
                        <Box mt={2} display="flex" justifyContent="center" alignItems="center">
                            <Button variant="contained" color="primary" type="submit">
                                Search
                            </Button>
                        </Box>
                    </form>
                    <Dialog
                        open={open}
                        onClose={handleClose}
                        maxWidth="md" // Set maxWidth to 'md', 'lg', 'xl', or 'false'
                        PaperProps={{ style: { width: isMobile ? '100%' : '80%', marginLeft:10,marginRight:10 } }} // Set a custom width
                    >
                        <DialogTitle>

                            <Typography variant="h6" component="div" align="center">Search Result</Typography>
                            <Box display="flex" justifyContent="space-around" alignItems="center">
                                <Button
                                    startIcon={<DeleteIcon />}
                                    color="error"
                                    onClick={() => deleteItem(searchResult.id)}
                                >
                                    Delete
                                </Button>
                                <Button
                                    startIcon={<HistoryIcon />}
                                    color="warning"
                                    onClick={() => handleHistoryClick()}
                                >
                                    History
                                </Button>
                            </Box>

                            <IconButton
                                edge="end"
                                color="inherit"
                                onClick={handleClose}
                                aria-label="close"
                                style={{ position: 'absolute', right: theme.spacing(2), top: theme.spacing(1) }}
                            >
                                <CloseIcon />
                            </IconButton>
                        </DialogTitle>
                        <DialogContent sx={{p:1,pb:1}}>
                            <TableContainer component={Paper}>
                                <Table sx={{ minWidth: 250 }} aria-label="simple table">
                                    <TableBody>
                                        <TableRow>
                                            <TableCell>Bearing Number</TableCell>
                                            <TableCell component="th" scope="row">
                                                {searchResult.itemId}
                                            </TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell>Bearing Type</TableCell>
                                            <TableCell><b>{searchResult.itemDescription}</b></TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell>Locker ID</TableCell>
                                            <TableCell><b>{searchResult.lockerId}</b></TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell>Compartment ID</TableCell>
                                            <TableCell><b>{searchResult.compartmentId}</b></TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell>Shelf ID</TableCell>
                                            <TableCell><b>{searchResult.shelfId}</b></TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell>Quantity</TableCell>
                                            <TableCell style={{ paddingLeft: 0, paddingRight: 0 }}>
                                                <Grid container direction="row" alignItems="center">
                                                    <QuantityInput isMobile={isMobile} quantity={inputQuantity} setQuantity={setInputQuantity} />
                                                    <Tooltip title="Update Quantity">
                                                        <IconButton
                                                            color="primary"
                                                            style={{ marginLeft: theme.spacing(1) }}
                                                            onClick={updateQuantity}
                                                        >
                                                            <SaveIcon />
                                                        </IconButton>
                                                    </Tooltip>
                                                </Grid>


                                            </TableCell>
                                        </TableRow>
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </DialogContent>
                        <HistoryDialog isMobile={isMobile} open={historyDialogOpen} data={quantityChanges} handleClose={handleHistoryDialogClose} />
                        <Backdrop
                            sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                            open={backdropOpen}
                        >
                            <CircularProgress color="inherit" />
                        </Backdrop>
                        {alertOpen ? (
                            <Snackbar
                                open={alertOpen}
                                autoHideDuration={2000}
                                onClose={() => setAlertOpen(false)}
                                sx={{
                                    width: '20%',
                                    height: '50px'// Adjust this value as needed
                                }}
                            >
                                <Alert
                                    sx={{ width: isMobile ? '70%' : '40%' }}
                                    onClose={() => setAlertOpen(false)}
                                    variant="filled"
                                    severity="success"
                                >
                                    Quantity Updated
                                </Alert>
                            </Snackbar>
                        ) : (
                            <div style={{ width: '20%', height: '48px' }}> {/* Adjust width and height as needed */}
                                {/* Content of the div */}
                            </div>
                        )}
                    </Dialog>
                </Box>
            </Paper>
        </Grid>
    );
}

export default HomePage;