import axios from "axios";

const API_URL = process.env.REACT_APP_API_URL;

const api = axios.create({
    baseURL: API_URL+'api/'
});
const adminApi = axios.create({
    baseURL: API_URL+'admin/v1/'
});

api.interceptors.request.use((config) => {
    const token = localStorage.getItem('token');

    if (token) {
        config.headers['Authorization'] = 'Bearer ' + token;
    }

    return config;
}, (error) => {
    return Promise.reject(error);
});

adminApi.interceptors.request.use((config) => {
    const token = localStorage.getItem('token');

    if (token) {
        config.headers['Authorization'] = 'Bearer ' + token;
    }

    return config;
}, (error) => {
    return Promise.reject(error);
});

export default api;
export { adminApi };