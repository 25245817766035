import React, { useEffect, useState} from 'react';
import {AppBar, Toolbar, Typography, IconButton, Menu, MenuItem, Button, useTheme} from '@mui/material';
import LogoutIcon from '@mui/icons-material/Logout';
import MenuIcon from '@mui/icons-material/Menu';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import PersonIcon from '@mui/icons-material/Person';
import { useNavigate } from 'react-router-dom';
import logo from '../../Assets/logo.svg';
function AppBarComponent({children}) {

    const navigate = useNavigate();
    const [anchorEl, setAnchorEl] = useState(null);
    const pages = [
        {name: 'Add', link: '/add-item'},

    ];

    const handleLogout = () => {
        localStorage.removeItem('token');
        localStorage.removeItem('isLoggedIn');
        localStorage.removeItem('phone');
        localStorage.removeItem('id');
        localStorage.removeItem('name');
        handleMenuClose();
        navigate('/login'); // Redirect to login page
    };
    let isLoggedIn = localStorage.getItem('isLoggedIn');
    console.log(isLoggedIn);
    const [name, setName] = useState('');
    useEffect(() => {
        // Get name from local storage or set it to 'User'
        let fullName = localStorage.getItem('name') || 'User';

        // Split the name by space and take the first part
        let firstName = fullName.split(' ')[0];

        setName(firstName);
    }, [isLoggedIn]); // Add isLoggedIn as a dependency

    const handleMenuOpen = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleMenuClose = () => {
        setAnchorEl(null);
    };



    const theme = useTheme();
    return (
        <AppBar position="static">
            <Toolbar>

                <Button onClick={() => navigate('/home')} style={{cursor: 'pointer'}}>
                    <img src={logo} alt="Logo" style={{height: '40px', objectFit: 'contain', filter: theme.palette.mode === 'dark' ? 'invert(1)' : 'invert(0)'}}/>
                </Button>

                {isLoggedIn && (
                    <>
                        <Typography variant="h6" component="div" sx={{flexGrow: 4, textAlign: 'center'}}>
                            Hello {name}
                        </Typography>
                        {pages.map((page, index) => (
                            <Button color="inherit" key={index} onClick={() => navigate(page.link)}>
                                {page.name}
                            </Button>
                        ))}

                        <IconButton color="inherit" onClick={handleMenuOpen}>
                            <MenuIcon/>
                        </IconButton>
                        <Menu
                            anchorEl={anchorEl}
                            open={Boolean(anchorEl)}
                            onClose={handleMenuClose}
                        >
                            <MenuItem>{children}</MenuItem>
                            <MenuItem onClick={() => navigate('/profile')}>
                                <IconButton color="inherit">
                                    <PersonIcon/>
                                </IconButton>
                                <Typography variant="body1">Profile</Typography>
                            </MenuItem>
                            <MenuItem onClick={() => {
                                handleMenuClose();
                                navigate('/register');
                            }}>
                                <IconButton color="inherit">
                                    <PersonAddIcon/>
                                </IconButton>
                                <Typography variant="body1">Register User</Typography>
                            </MenuItem>
                            <MenuItem onClick={handleLogout}>
                                <IconButton color="inherit">
                                    <LogoutIcon/>
                                </IconButton>
                                <Typography variant="body1">Logout</Typography>
                            </MenuItem>
                        </Menu>
                    </>
                )}
            </Toolbar>
        </AppBar>
    );
}

export default AppBarComponent;
