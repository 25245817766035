// inventoryService.js
import api from "../Provider/api";
import handleError from "../Provider/errorHandler";

const addLocker = async (lockerData) => {
    try {
        return await api.post(`locker/v1/`, lockerData);
    } catch (error) {
        return handleError(error);
    }
}

const getLockers = async () => {
    try {
        return await api.get(`locker/v1/`);
    } catch (error) {
        handleError(error);
    }
}

const getLockerAvailability = async (lockerId) => {
    try {
        return await api.get(`locker/v1/${lockerId}/availability`);
    } catch (error) {
        handleError(error);
    }
}

const addCompartment = async (compartmentData) => {
    try {
        return await api.post(`compartment/v1/`, compartmentData);
    } catch (error) {
        return handleError(error);
    }
}

const getCompartmentsByLocker = async (lockerId) => {
    try {
        return await api.get(`compartment/v1/${lockerId}`);
    } catch (error) {
        handleError(error);
    }
}

const addShelf = async (shelfData) => {
    try {
        return await api.post(`shelf/v1/`, shelfData);
    } catch (error) {
        return handleError(error);
    }
}

const getShelfByCompartment = async (compartmentId) => {
    try {
        return await api.get(`shelf/v1/${compartmentId}`);
    } catch (error) {
        handleError(error);
    }
}

const inventoryService = {
    addLocker,
    getLockers,
    getLockerAvailability,
    addCompartment,
    getCompartmentsByLocker,
    addShelf,
    getShelfByCompartment
};

export default inventoryService;