import React, {useEffect, useState} from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import LoginPage from './Pages/Login/Login';
import ProtectedRoute from './Provider/ProtectedRoutes';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { styled } from '@mui/system';
import AppBarComponent from './Components/AppBar/AppBar';
import {FormControlLabel} from "@mui/material";
import ThemeToggleSwitch from "./Components/ThemeToggleSwitch/ThemeToggleSwitch";

const AppContainer = styled('div')(({ theme }) => ({
    backgroundColor: theme.palette.background.default,
    height: '100vh',
    margin: 0,
    color: theme.palette.text.primary,
}));

function App() {

    const [darkMode,setDarkMode]= useState(()=> {
        const savedTheme = localStorage.getItem('darkMode');
        return savedTheme === 'true' ? true : false;
    });

    const theme = createTheme({
        palette: {
            mode: darkMode ? 'dark' : 'light',
        },
    });

    useEffect(() => {
        localStorage.setItem('darkMode', JSON.stringify(darkMode));
    }, [darkMode]);

    const handleThemeChange = () => {
        setDarkMode(!darkMode);
    };

    return (
        <ThemeProvider theme={theme}>
            <AppContainer theme={theme}>
                <Router>
                    <AppBarComponent>
                        <FormControlLabel
                            control={<ThemeToggleSwitch checked={darkMode} onChange={handleThemeChange} />}
                            label={darkMode ? "Dark Mode" : "Light Mode"}
                        />
                    </AppBarComponent>
                    <Routes>
                        <Route path="/login" element={<LoginPage />} />
                        <Route path="/*" element={<ProtectedRoute />} />
                    </Routes>
                </Router>
            </AppContainer>
        </ThemeProvider>
    );
}

export default App;