import React, { useState } from 'react';
import {Dialog, DialogTitle, DialogContent, DialogActions, Button, TextField, useTheme} from '@mui/material';
import inventoryService from '../../Services/inventoryService';
import Swal from "sweetalert2";

const CustomDialog = ({ children, dialogType, open, handleClose, lockerId,compartmentId, selectedVal }) => {
    const [inputValue, setInputValue] = useState('');

    const config = {
        'Locker': {
            title: 'Add Locker',
            label: 'Enter Locker ID',
            service: inventoryService.addLocker,
            data: { lockerId: inputValue }
        },
        'Compartment': {
            title: `Add Compartment for Locker ${selectedVal} `,
            label: 'Enter Compartment ID',
            service: inventoryService.addCompartment,
            data: { locker: lockerId, compartmentId: inputValue }
        },
        'Shelf': {
            title: `Add Shelf for Compartment ${selectedVal} `,
            label: 'Enter Shelf ID',
            service: inventoryService.addShelf,
            data: { compartment: compartmentId, shelfId: inputValue }
        }
    };
    const theme = useTheme();
    const handleSubmit = async () => {
        const { service, data } = config[dialogType];
        handleClose();
        Swal.fire({
            html: `<span style="color: ${theme.palette.mode === 'dark' ? '#fff' : '#000'}">Processing...</span>`, // set the title with a custom style
            background: theme.palette.mode === 'dark' ? '#424242' : '#fff',
            didOpen: () => {
                Swal.showLoading();
            },
            allowOutsideClick: () => !Swal.isLoading()
        });
        try {
            const response = await service(data);
            if (response.status === 409) {
                Swal.close();
                Swal.fire({
                    html: `<span style="color: ${theme.palette.mode === 'dark' ? '#fff' : '#000'}">${response.data.message}</span>`, // set the title with a custom style
                    icon: 'error',
                    background: theme.palette.mode === 'dark' ? '#424242' : '#fff',
                });
            } else {
                Swal.close();
                Swal.fire({
                    html: `<span style="color: ${theme.palette.mode === 'dark' ? '#fff' : '#000'}">Success</span>`, // set the title with a custom style
                    text: 'Item added successfully.',
                    icon: 'success',
                    background: theme.palette.mode === 'dark' ? '#424242' : '#fff',
                });
            }
        } catch (error) {
            Swal.close();
            console.error(error);
            Swal.fire({
                html: `<span style="color: ${theme.palette.mode === 'dark' ? '#fff' : '#000'}">Error</span>`, // set the title with a custom style
                text: 'An error occurred while adding the item.',
                icon: 'error',
                background: theme.palette.mode === 'dark' ? '#424242' : '#fff',
            });
        }
        setInputValue('');
        handleClose();
    };

    const { title, label } = config[dialogType] || {};

    return (
        <Dialog open={open} onClose={handleClose}>
            <DialogTitle>
                {title}
            </DialogTitle>
            <DialogContent>
                <TextField label={label} variant="outlined" fullWidth margin="normal" value={inputValue} onChange={(e) => setInputValue(e.target.value)}/>
                {children}
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose} color="primary">
                    Close
                </Button>
                <Button onClick={handleSubmit} color="primary">
                    Submit
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default CustomDialog;