import React, { useState, useEffect } from 'react';
import {
    Button,
    TextField,
    Typography,
    Grid,
    Paper,
    Box,
    Autocomplete,
    useMediaQuery
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import InventoryService from '../../Services/inventoryService';
import AddDialog from "../../Components/AddDialog/AddDialog";
import itemService from "../../Services/itemService";
import Swal from "sweetalert2";

function AddItemForm() {
    const theme = useTheme();
    const [itemId, setItemId] = useState('');
    const [itemDescription, setItemDescription] = useState('');
    const [quantity, setQuantity] = useState('');
    const [lockers, setLockers] = useState([]);
    const [compartments, setCompartments] = useState([]);
    const [shelves, setShelves] = useState([]);
    const [selectedShelf, setSelectedShelf] = useState('');
    const [selectedLocker, setSelectedLocker] = useState('');
    const [selectedCompartment, setSelectedCompartment] = useState('');

    useEffect(() => {
        fetchLockers();
    }, []);

    useEffect(() => {
        if (selectedLocker) {
            fetchCompartments(selectedLocker);
        }
    }, [selectedLocker]);

    useEffect(() => {
        if (selectedCompartment) {
            fetchShelves(selectedCompartment);
        }
    }, [selectedCompartment]);

    const fetchLockers = async () => {
        const data = await InventoryService.getLockers();
        console.log(data.data)
        setLockers(data.data);
    };

    const fetchCompartments = async (lockerId) => {
        const data = await InventoryService.getCompartmentsByLocker(lockerId);
        setCompartments(data.data);
    };

    const fetchShelves = async (compartmentId) => {
        const data = await InventoryService.getShelfByCompartment(compartmentId);
        setShelves(data.data);
    };

    const [resetKey, setResetKey] = useState(0); // Add this line
    const [resetCompartmentKey, setResetCompartmentKey] = useState(0);
    const [resetShelfKey, setResetShelfKey] = useState(0);

    const resetForm = async() => {
        setItemId('');
        setItemDescription('');
        setQuantity('');
        setSelectedShelf(null);
        setSelectedLocker(null);
        setSelectedCompartment(null);
        setResetKey(prevKey => prevKey + 1); // Increment the resetKey state variable
        setResetCompartmentKey(prevKey => prevKey + 1);
        setResetShelfKey(prevKey => prevKey + 1);
    }
    const handleSubmit = async (event) => {
        event.preventDefault();

        const itemData = {
            itemId,
            itemDescription,
            quantity,
            shelf: selectedShelf
        };

        Swal.fire({
            html: `<span style="color: ${theme.palette.mode === 'dark' ? '#fff' : '#000'}">Processing...</span>`, // set the title with a custom style
            background: theme.palette.mode === 'dark' ? '#424242' : '#fff',
            didOpen: () => {
                Swal.showLoading();
            },
            allowOutsideClick: () => !Swal.isLoading()
        });

        try {
            const response = await itemService.addItem(itemData);
            console.log(response);
            if (response.status === 409) {
                console.log(response)
                Swal.close();
                Swal.fire({
                    html: `<span style="color: ${theme.palette.mode === 'dark' ? '#fff' : '#000'}">${response.data.message}</span>`, // set the title with a custom style
                    icon: 'error',
                    background: theme.palette.mode === 'dark' ? '#424242' : '#fff',
                });
            } else {
                Swal.close();
                Swal.fire({
                    html: `<span style="color: ${theme.palette.mode === 'dark' ? '#fff' : '#000'}">Success</span>`, // set the title with a custom style
                    text: 'Bearing added successfully.',
                    icon: 'success',
                    background: theme.palette.mode === 'dark' ? '#424242' : '#fff',
                });

                // Reset all the state variables
                resetForm();
            }
        } catch (error) {
            Swal.close();
            console.error(error);
            Swal.fire({
                html: `<span style="color: ${theme.palette.mode === 'dark' ? '#fff' : '#000'}">Error</span>`, // set the title with a custom style
                text: 'An error occurred while adding the bearing.',
                icon: 'error',
                background: theme.palette.mode === 'dark' ? '#424242' : '#fff',
            });
        }
    };
    const [openDialog, setOpenDialog] = useState(false); // Add this line

    const [dialogType, setDialogType] = useState(null); // Add this line
    const [selectedVal,setSelectedVal] = useState(''); // Add this line

    const handleOpenDialog = (type) => {
        setDialogType(type);
        console.log(selectedVal)
        setOpenDialog(true);

    };

    const handleCloseDialog = () => {
        setOpenDialog(false);
        fetchLockers();
        if (selectedLocker) {
            fetchCompartments(selectedLocker);
        }
        if (selectedCompartment) {
            fetchShelves(selectedCompartment);
        }

    };
    const isMobile = useMediaQuery(theme => theme.breakpoints.down('sm'));

    return (

        <Grid container direction="column" justifyContent="center" alignItems="center" sx={{mt:2}} >
            <Paper elevation={10} style={!isMobile ? {}:{ width: '90vw' }} >
                <Box p={2} display="flex" flexDirection="column" alignItems="center" justifyContent="center" sx={!isMobile ? {}:{mb:2}}>
                    <Typography sx={{m: 2}} variant="h4" component="h1" gutterBottom>
                        Add Bearing
                    </Typography>
                    <form noValidate autoComplete="off" onSubmit={handleSubmit}>
                        <Box display="flex" flexDirection="column" elevation={10} sx={isMobile ? {width:"80vw"}:{width:"50vw"}}>
                            <Box display="flex" alignItems="center" marginBottom={2}>
                                <Autocomplete
                                    key={resetKey}
                                    id="locker-autocomplete"
                                    options={lockers}
                                    getOptionLabel={(option) => option.lockerId}
                                    sx={{ width: '85%' }}
                                    renderInput={(params) => <TextField {...params} label="Locker ID" variant="outlined" />}
                                    onChange={(event, newValue) => {
                                        setSelectedLocker(newValue?._id);
                                        setSelectedVal(newValue?.lockerId);
                                        setSelectedCompartment(null);
                                        setResetCompartmentKey(prevKey => prevKey + 1);
                                        setSelectedShelf(null);
                                        setResetShelfKey(prevKey => prevKey + 1);
                                    }}
                                />
                                <Button variant="contained" color="primary" sx={{ marginLeft: '1em' }} onClick={() => handleOpenDialog('Locker')}>
                                    Add
                                </Button>
                            </Box>
                            <Box display="flex" alignItems="center" marginBottom={2}>
                                <Autocomplete
                                    key={resetCompartmentKey}
                                    id="compartment-autocomplete"
                                    options={compartments}
                                    getOptionLabel={(option) => option.compartmentId}
                                    sx={{ width: '85%' }}
                                    renderInput={(params) => <TextField {...params} label="Compartment ID" variant="outlined" />}
                                    onChange={(event, newValue) => {
                                        setSelectedCompartment(newValue?._id);
                                        setSelectedVal(newValue?.compartmentId);
                                        setSelectedShelf(null);
                                        setResetShelfKey(prevKey => prevKey + 1);
                                    }}
                                />
                                <Button
                                    variant="contained"
                                    color="primary"
                                    sx={{ marginLeft: '1em' }}
                                    onClick={() => handleOpenDialog('Compartment')}
                                    disabled={!selectedLocker} // Add this line
                                >
                                    Add
                                </Button>
                            </Box>
                            <Box display="flex" alignItems="center" marginBottom={2}>
                                <Autocomplete
                                    key={resetShelfKey}
                                    id="shelf-autocomplete"
                                    options={shelves}
                                    getOptionLabel={(option) => option.shelfId}
                                    sx={{ width: '85%' }}
                                    renderInput={(params) => <TextField {...params} label="Shelf ID" variant="outlined" />}
                                    onChange={(event, newValue) => {
                                        setSelectedShelf(newValue?._id);
                                    }}
                                />
                                <Button
                                    variant="contained"
                                    color="primary"
                                    sx={{ marginLeft: '1em' }}
                                    onClick={() => handleOpenDialog('Shelf')}
                                    disabled={!selectedCompartment} // Add this line
                                >
                                    Add
                                </Button>
                            </Box>
                            <AddDialog
                                open={openDialog}
                                handleClose={handleCloseDialog}
                                dialogType={dialogType}
                                lockerId={selectedLocker}
                                compartmentId={selectedCompartment}
                                selectedVal={selectedVal}
                            />
                            <TextField
                                id="item-id"
                                label="Bearing Number"
                                variant="outlined"
                                value={itemId}
                                onChange={(e) => setItemId(e.target.value)}
                                style={{marginBottom: theme.spacing(2)}}
                            />
                            <TextField
                                id="item-description"
                                label="Bearing Description"
                                variant="outlined"
                                value={itemDescription}
                                onChange={(e) => setItemDescription(e.target.value)}
                                style={{marginBottom: theme.spacing(2)}}
                            />
                            <TextField
                                id="quantity"
                                label="Quantity"
                                type="number"
                                variant="outlined"
                                value={quantity}
                                onChange={(e) => setQuantity(e.target.value)}
                                style={{marginBottom: theme.spacing(2)}}
                            />
                            <Button
                                variant="contained"
                                color="primary"
                                type="submit"
                                disabled={!selectedLocker || !selectedCompartment || !selectedShelf || !itemId || !itemDescription || !quantity}
                            >
                                Add Bearing
                            </Button>
                        </Box>
                    </form>
                </Box>
            </Paper>
        </Grid>
    );
}

export default AddItemForm;
