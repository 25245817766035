// errorHandler.js
import authService from '../Services/AuthService';

const handleError = (error) => {
    if (error.response) {
        if (error.response.status === 401 || error.response.status === 403) {
            authService.logout();
            window.location.href = '/login';
        } else if (error.response.status === 409) {
            return error.response;
        }
    }
};

export default handleError;