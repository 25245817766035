import React, { useCallback } from 'react';
import {TextField,InputAdornment,IconButton,Tooltip} from '@mui/material';

import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';

export default function QuantityInput({ isMobile,quantity, setQuantity }) {
    const handleChange = useCallback((event) => {
        const value = Number(event.target.value);
        setQuantity(Math.min(Math.max(value, 0), 9999));
    }, [setQuantity]);

    const handleIncrement = useCallback(() => {
        setQuantity(prevQuantity => Math.min(prevQuantity + 1, 9999));
    }, [setQuantity]);

    const handleDecrement = useCallback(() => {
        setQuantity(prevQuantity => Math.max(prevQuantity - 1, 0));
    }, [setQuantity]);

    return (
        <TextField
            type="number"
            value={quantity}
            onChange={handleChange}
            sx={{
                width: isMobile ? '80%' : '65%',
                p: 0,
                '& input[type=number]': {
                    '-moz-appearance': 'textfield',
                    '&::-webkit-outer-spin-button, &::-webkit-inner-spin-button': {
                        '-webkit-appearance': 'none',
                        margin: 0,
                    },
                },
            }}
            InputProps={{
                startAdornment: (
                    <InputAdornment position="start">
                        <Tooltip title="Decrease">
                            <IconButton onClick={handleDecrement} color="error" size="large" style={{ padding: 12, marginRight: 8 }}>
                                <RemoveCircleOutlineIcon fontSize="small" />
                            </IconButton>
                        </Tooltip>
                    </InputAdornment>
                ),
                endAdornment: (
                    <InputAdornment position="end">
                    <Tooltip title="Increase">
                        <IconButton onClick={handleIncrement} color="success" size="large" style={{ padding: 12, marginLeft: 8 }}>
                            <AddCircleOutlineIcon fontSize="small" />
                        </IconButton>
                    </Tooltip>
                    </InputAdornment>
                ),
                inputProps: {
                    min: 0,
                    max: 9999,
                },
            }}
        />
    );
}